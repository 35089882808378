import { Env } from 'types/enums/env';
import { Environment } from 'types/interfaces/environment';

import packageInfo from '../../../package.json';

const ENV: Env = process.env.REACT_APP_ENV as Env;

const environments: { [key in Env]: Partial<Environment> } = {
  // eslint-disable-next-line @typescript-eslint/dot-notation
  [Env['dev']]: {
    PROTOCOL: 'http',
    HOST: 'localhost:3000',
  },
  [Env['prod']]: {
    PROTOCOL: 'https',
    HOST: '77.223.96.185',
  },
};

export const environment: Environment = {
  ENV,
  VERSION: packageInfo?.version,
  BASENAME: process.env.PUBLIC_URL || '',
  PUBLIC_URL: process.env.PUBLIC_URL || '',
  ...environments[ENV]
} as Environment;

export default environment;

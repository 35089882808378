import { useMemo, useState } from 'react';

import environment from 'shared/config/environment';
import { Env } from 'types/enums/env';

interface Query<T> {
  request: () => void,
  isSuccess: boolean,
  isFetching: boolean,
  isError: boolean,
  result?: T,
  error?: Error,
}

export type RequestQueryValue = string | number | boolean | null | undefined;

type Override<T, U> = Pick<T, Exclude<keyof T, keyof U>> & U;
export type PlainObject = Record<string, unknown>;
export type QueryParams = Record<string, RequestQueryValue>;
export type Headers = Record<string, string>;

export enum Method {
  GET = 'get',
  POST = 'post',
  PATCH = 'patch',
  PUT = 'put',
  DELETE = 'delete',
};

export type RequestParams<
  B extends PlainObject | undefined,
  Q extends QueryParams | undefined,
  H extends Headers | undefined,
> = Override<RequestInit, { body?: RequestInit['body'] | B }> & {
  method?: Method,
  headers?: H,
  query?: Q,
};

const useQuery = <T>(
  path: string,
  {
    method = Method.GET,
    headers = {},
    query,
    body,
  }: RequestParams<PlainObject, QueryParams, Headers> = {}
): Query<T> => {
  const [state, setState] = useState<Omit<Query<T>, 'request'>>({
    isSuccess: false,
    isFetching: false,
    isError: false,
  });

  const url = useMemo(() => {
    let queryString = '';
    if (query) {
      const tmp = Object.entries(query)
        .reduce((acc, [key, value]) => {
          if (value === undefined || value === '' || value === null) {
            return acc;
          }
          const param = `${encodeURIComponent(key)}=${encodeURIComponent(value)}`;
          return [...acc, param];
        }, [] as RequestQueryValue[])
        .join('&');
      if (tmp) {
        queryString = `?${tmp}`;
      }
    }

    if (environment.ENV === Env.prod) {
      return `/api${path}${queryString}`;
    }

    return `${environment.PROTOCOL}://${environment.HOST}/api${path}${queryString}`;
  }, [path, query]);

  const request = () => {
    setState((state) => ({
      ...state,
      isFetching: true,
    }));

    fetch(url, {
      method,
      headers: { 'Content-Type': 'application/json', ...headers },
      body: body ? JSON.stringify(body) : undefined,
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error();
        }
        return response.json();
      })
      .then((result) => setState({
        result,
        isSuccess: true,
        isFetching: false,
        isError: false,
      }))
      .catch((error) => setState({
        error,
        isSuccess: false,
        isFetching: false,
        isError: true,
      }));
  };

  return { ...state, request };
};

export default useQuery;

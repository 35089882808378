import clsx from 'clsx';

import { IconProps } from 'types/interfaces/icons';

export const Route = ({
  fill = 'currentColor',
  className,
}: IconProps) => (
  <svg
    className={clsx(className)}
    width="25"
    height="24"
    viewBox="0 0 25 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5.96991 10.75C5.25991 10.75 4.53991 10.49 3.98991 9.96C2.14991 8.21 1.40991 6.3 1.83991 4.43C2.34991 2.23 4.27991 1.25 5.96991 1.25C7.65991 1.25 9.59991 2.24 10.0999 4.43C10.5299 6.3 9.77991 8.21 7.94991 9.96C7.39991 10.49 6.68991 10.75 5.96991 10.75ZM3.29991 4.77C2.91991 6.44 4.02991 7.92 5.02991 8.87C5.55991 9.38 6.38991 9.37 6.91991 8.87C7.91991 7.92 9.02991 6.44 8.63991 4.76C8.29991 3.28 6.98991 2.75 5.97991 2.75C4.95991 2.75 3.64991 3.28 3.29991 4.77Z"
      fill={fill}
    />
    <path
      d="M6.01001 6.2002C5.46001 6.2002 5.01001 5.7502 5.01001 5.2002C5.01001 4.6502 5.45001 4.2002 6.01001 4.2002H6.02001C6.57001 4.2002 7.02001 4.6502 7.02001 5.2002C7.02001 5.7502 6.57001 6.2002 6.01001 6.2002Z"
      fill={fill}
    />
    <path
      d="M18.9901 22.7502C18.2801 22.7502 17.5601 22.4902 17.0001 21.9602C15.1501 20.2102 14.4001 18.3002 14.8401 16.4202C15.3501 14.2302 17.2901 13.2402 18.9901 13.2402C20.6901 13.2402 22.6301 14.2302 23.1401 16.4202C23.5701 18.2902 22.8201 20.2102 20.9701 21.9502C20.4201 22.4902 19.7101 22.7502 18.9901 22.7502ZM18.9901 14.7502C17.9701 14.7502 16.6501 15.2802 16.3001 16.7702C15.9101 18.4402 17.0301 19.9302 18.0301 20.8802C18.5601 21.3902 19.4001 21.3902 19.9401 20.8802C20.9401 19.9302 22.0601 18.4502 21.6701 16.7802C21.3401 15.2802 20.0201 14.7502 18.9901 14.7502Z"
      fill={fill}
    />
    <path
      d="M19.03 18.2002C18.48 18.2002 18.03 17.7502 18.03 17.2002C18.03 16.6502 18.47 16.2002 19.03 16.2002H19.04C19.59 16.2002 20.04 16.6502 20.04 17.2002C20.04 17.7502 19.58 18.2002 19.03 18.2002Z"
      fill={fill}
    />
    <path
      d="M12.4999 19.75H9.81994C8.65994 19.75 7.64994 19.05 7.24994 17.97C6.83994 16.89 7.13994 15.7 8.00994 14.93L15.9999 7.94C16.4799 7.52 16.4899 6.95 16.3499 6.56C16.1999 6.17 15.8199 5.75 15.1799 5.75H12.4999C12.0899 5.75 11.7499 5.41 11.7499 5C11.7499 4.59 12.0899 4.25 12.4999 4.25H15.1799C16.3399 4.25 17.3499 4.95 17.7499 6.03C18.1599 7.11 17.8599 8.3 16.9899 9.07L8.99994 16.06C8.51994 16.48 8.50994 17.05 8.64994 17.44C8.79994 17.83 9.17994 18.25 9.81994 18.25H12.4999C12.9099 18.25 13.2499 18.59 13.2499 19C13.2499 19.41 12.9099 19.75 12.4999 19.75Z"
      fill={fill}
    />
    <path
      d="M12.4999 19.75H9.81994C8.65994 19.75 7.64994 19.05 7.24994 17.97C6.83994 16.89 7.13994 15.7 8.00994 14.93L15.9999 7.94C16.4799 7.52 16.4899 6.95 16.3499 6.56C16.1999 6.17 15.8199 5.75 15.1799 5.75H12.4999C12.0899 5.75 11.7499 5.41 11.7499 5C11.7499 4.59 12.0899 4.25 12.4999 4.25H15.1799C16.3399 4.25 17.3499 4.95 17.7499 6.03C18.1599 7.11 17.8599 8.3 16.9899 9.07L8.99994 16.06C8.51994 16.48 8.50994 17.05 8.64994 17.44C8.79994 17.83 9.17994 18.25 9.81994 18.25H12.4999C12.9099 18.25 13.2499 18.59 13.2499 19C13.2499 19.41 12.9099 19.75 12.4999 19.75Z"
      fill={fill}
    />
  </svg>
);

import { ReactNode } from 'react';
import { NavLink } from 'react-router-dom';
import clsx from 'clsx';

import styles from './MenuItem.module.scss';
import { SectionName } from 'types/enums/app';

export interface Props {
  icon: ReactNode,
  title: string,
  path: SectionName | string,
  active: boolean,
}

export const MenuItem = ({ icon, title, path, active }: Props) => {
  return (
    <NavLink
      className={clsx(styles.navLink, {
        [styles.navLink__active]: active,
      })}
      to={path}
    >
      <div>
        {icon}
        <div>{title}</div>
      </div>
    </NavLink>
  );
};

import { useSelector } from 'react-redux';
import { useEffect } from 'react';
import { Button, Flex } from 'antd';
import clsx from 'clsx';

import styles from './Routes.module.scss';
import useFetchRouteFeed from 'api/useFetchRouteFeed';
import { RouteFeed } from './ui/route-feed';
import { RouteModel } from 'models/RouteModel';
import { selectScrollingOnTop } from 'app/providers/selectors/app';

// @ts-nocheck
const routes = [
  {
    'id': '9b55240c-fa9c-499f-b130-e0c2f0d6591f',
    'points': [
      {
        'title': 'Сочи',
        'images': [],
        'description': ''
      },
      {
        'title': 'Москва',
        'images': [],
        'description': ''
      },
      {
        'title': 'Санкт-Петербург',
        'images': [],
        'description': ''
      }
    ],
    'countDrivers': 11,
    'userId': null,
    'date': '2024-09-26T13:37:54.218Z',
    'description': 'Путешествие по маршруту Сочи — Москва — Санкт-Петербург — отличный способ познакомиться с красотами России. Начните своё путешествие с Сочи, где вас ждут живописные пейзажи, тёплое море и уникальные культурные достопримечательности. Затем отправляйтесь в Москву, столицу страны, чтобы насладиться её архитектурой, музеями и историческими местами. Завершите поездку посещением Санкт-Петербурга, известного своими культурными мероприятиями, музеями и архитектурой.',
    'price': 23500
  },
  {
    'id': '28fa62f3-b239-4580-9888-ca53af4e006b',
    'points': [
      {
        'title': 'Сочи',
        'images': [
          {
            'alt': 'Господи, я с Тобой!',
            'src': 'https://map.autogoda.ru/wp-content/uploads/2023/02/%D0%9D%D0%BE%D0%B2%D1%8B%D0%B9-%D0%90%D1%84%D0%BE%D0%BD.jpg'
          }
        ],
        'description': ''
      },
      {
        'title': 'Гагра',
        'images': [],
        'description': ''
      },
      {
        'title': 'Новый Афон',
        'images': [],
        'description': ''
      }
    ],
    'countDrivers': 13,
    'userId': null,
    'date': '2024-09-26T10:32:18.607Z',
    'description': 'Приглашаем в путешествие по «стране души» на современном микроавтобусе Мерседес. Вы оцените все классические достопримечательности Абхазии: озеро Рица, водопады, Юпшарский каньон, Новоафонский монастырь и многое другое. Прикоснётесь к местной истории и полюбуетесь сказочной природой.',
    'price': 3500
  },
  {
    'id': '8b7584a5-114c-46d9-92f4-65086dcfbb90',
    'points': [
      {
        'title': 'Омск',
        'images': [],
        'description': ''
      },
      {
        'title': 'Новосибирск',
        'images': [],
        'description': ''
      },
      {
        'title': 'Томск',
        'images': [],
        'description': ''
      }
    ],
    'countDrivers': 7,
    'userId': null,
    'date': '2024-09-26T13:37:54.218Z',
    'description': 'Путешествие Омск — Новосибирск — Томск может быть интересным и познавательным. В Омске можно посетить музеи и театры, а также прогуляться по набережной Иртыша. В Новосибирске стоит посмотреть Новосибирский зоопарк и Академгородок, а в Томске — музеи и университетские корпуса.',
    'price': 13500
  }
] as RouteModel[];

export const Routes = () => {
  const scrollingOnTop = useSelector(selectScrollingOnTop);
  const {
    result: routes1,
    request: fetchRoutes,
    isFetching: isFetchingRoutes,
  } = useFetchRouteFeed();

  useEffect(() => {
    fetchRoutes();
  }, []);

  if (isFetchingRoutes) {
    return <>Loading...</>;
  }

  return (
    <>
      <RouteFeed routes={routes!} />
      <Button
        type="link"
        className={clsx(styles.showMoreButton)}
      >
        РАЗВЕРНУТЬ ЕЩЁ
      </Button>
      <Flex
        justify="center"
        className={clsx(styles.addRouteButtonWrapper, {
          [styles.addRouteButtonWrapper__visible]: !scrollingOnTop,
          [styles.addRouteButtonWrapper__hidden]: scrollingOnTop,
        })}
      >
        <Button className={clsx(styles.addRouteButton)}>
          Создать свой маршрут
        </Button>
      </Flex>
    </>
  );
};

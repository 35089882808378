import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { TokenParsed } from 'types/interfaces/app';

export interface AppState {
  token?: string,
  tokenParsed?: TokenParsed,
  scrollingOnTop: boolean,
}

const initialState = {
  scrollingOnTop: true,
} as AppState;

export const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    setAuth(state, { payload }: PayloadAction<AppState>) {
      state.token = payload.token;
      state.tokenParsed = payload.tokenParsed;
    },
    clearAuth(state) {
      state.token = undefined;
      state.tokenParsed = undefined;
    },
    setScrollingOnTop(state, { payload }: PayloadAction<boolean>) {
      state.scrollingOnTop = payload;
    },
  },
});

export const appActions = appSlice.actions;
export const appReducer = appSlice.reducer;
export const appName = appSlice.name;

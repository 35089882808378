import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useRef, useState } from 'react';
import { Outlet } from 'react-router-dom';
import { Typography } from 'antd';
import moment from 'moment';
import clsx from 'clsx';
import 'moment/locale/ru';

import styles from './PageLayout.module.scss';
import { Footer } from '../footer';
import { Header } from '../header';
import { selectScrollingOnTop } from 'app/providers/selectors/app';
import { appActions } from 'app/providers/slices/app';

moment.locale('ru');

export const PageLayout = () => {
  const dispatch = useDispatch();
  const main = useRef<HTMLDivElement>(null);
  const scrollingOnTop = useSelector(selectScrollingOnTop);
  const [position, setPosition] = useState(main.current?.scrollTop ?? 0);

  useEffect(()=> {
    const handleScroll = () => {
      const scrollTop = main.current?.scrollTop ?? 0;
      const scrollHeight = main.current?.scrollHeight ?? 0;
      // const clientHeight = main.current?.clientHeight ?? 0;
      const offsetHeight = main.current?.offsetHeight ?? 0;

      const scrollingOnTop = (position > scrollTop) && scrollTop !== ((scrollHeight - offsetHeight));
      dispatch(appActions.setScrollingOnTop(scrollingOnTop));

      // if (scrollingOnTop) {
      //   console.log('position', position);
      //   console.log('scrollTop', scrollTop);
      //   console.log('scrollHeight', scrollHeight);
      //   console.log('clientHeight', clientHeight);
      //   console.log('offsetHeight', offsetHeight);
      //   console.log('scrollHeight - offsetHeigh', scrollHeight - offsetHeight);
      //   console.log('*************************');
      // }

      setPosition(scrollTop > 0 ? scrollTop : 1);
    };

    main.current?.addEventListener('scroll', handleScroll);

    return(() => main.current?.removeEventListener('scroll', handleScroll));
  });

  return (
    <>
      <Header />
      <main
        ref={main}
        className={styles.main}
      >
        <Typography.Title
          className={clsx(styles.title, {
            [styles.title__visible]: scrollingOnTop,
            [styles.title__hidden]: !scrollingOnTop,
            [styles.title__transparent]: position < 24,
          })}
          level={1}
          ellipsis={{
            rows: 1,
          }}
        >
          Популярные маршруты
        </Typography.Title>
        <section>
          <Outlet />
        </section>
      </main>
      <Footer />
    </>
  );
};

import clsx from 'clsx';

import { IconProps } from 'types/interfaces/icons';

export const UserOctagon = ({
  fill = 'currentColor',
  className,
}: IconProps) => (
  <svg
    className={clsx(className)}
    width="33"
    height="32"
    viewBox="0 0 33 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M16.5001 30.3332C15.6068 30.3332 14.7001 30.1065 13.9001 29.6399L5.98013 25.0665C4.38013 24.1332 3.38013 22.4132 3.38013 20.5599V11.4399C3.38013 9.58658 4.38013 7.86657 5.98013 6.93324L13.9001 2.35991C15.5001 1.42658 17.4868 1.42658 19.1001 2.35991L27.0201 6.93324C28.6201 7.86657 29.6201 9.58658 29.6201 11.4399V20.5599C29.6201 22.4132 28.6201 24.1332 27.0201 25.0665L19.1001 29.6399C18.3001 30.1065 17.3935 30.3332 16.5001 30.3332ZM16.5001 3.66655C15.9535 3.66655 15.3935 3.81323 14.9001 4.09323L6.98013 8.66655C5.99346 9.23989 5.38013 10.2932 5.38013 11.4399V20.5599C5.38013 21.6932 5.99346 22.7599 6.98013 23.3332L14.9001 27.9065C15.8868 28.4799 17.1135 28.4799 18.1001 27.9065L26.0201 23.3332C27.0068 22.7599 27.6201 21.7065 27.6201 20.5599V11.4399C27.6201 10.3066 27.0068 9.23989 26.0201 8.66655L18.1001 4.09323C17.6068 3.81323 17.0468 3.66655 16.5001 3.66655Z"
      fill={fill}
    />
    <path
      d="M16.5 15.6667C14.2333 15.6667 12.3933 13.8266 12.3933 11.56C12.3933 9.29331 14.2333 7.45337 16.5 7.45337C18.7666 7.45337 20.6066 9.29331 20.6066 11.56C20.6066 13.8266 18.7666 15.6667 16.5 15.6667ZM16.5 9.45337C15.34 9.45337 14.3933 10.4 14.3933 11.56C14.3933 12.72 15.34 13.6667 16.5 13.6667C17.66 13.6667 18.6066 12.72 18.6066 11.56C18.6066 10.4 17.66 9.45337 16.5 9.45337Z"
      fill={fill}
    />
    <path
      d="M21.8334 23.2135C21.2867 23.2135 20.8334 22.7602 20.8334 22.2135C20.8334 20.3735 18.8867 18.8669 16.5001 18.8669C14.1134 18.8669 12.1667 20.3735 12.1667 22.2135C12.1667 22.7602 11.7134 23.2135 11.1667 23.2135C10.6201 23.2135 10.1667 22.7602 10.1667 22.2135C10.1667 19.2669 13.0067 16.8669 16.5001 16.8669C19.9934 16.8669 22.8334 19.2669 22.8334 22.2135C22.8334 22.7602 22.3801 23.2135 21.8334 23.2135Z"
      fill={fill}
    />
  </svg>
);

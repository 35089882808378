import { configureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query';

import { Env } from 'types/enums/env';
import config from 'shared/config/environment';
import { appName, appReducer } from 'app/providers/slices/app';
import { dataName, dataReducer } from 'app/providers/slices/data';
import { uiName, uiReducer } from 'app/providers/slices/ui';

export const store = configureStore({
  reducer: {
    [appName]: appReducer,
    [dataName]: dataReducer,
    [uiName]: uiReducer,
  },
  devTools: config.ENV !== Env.prod,
});

setupListeners(store.dispatch);

export type AppDispatch = typeof store.dispatch;

export type RootState = ReturnType<typeof store.getState>;

import { NavLink } from 'react-router-dom';
import { Dropdown, Typography } from 'antd';
import clsx from 'clsx';

import styles from './Header.module.scss';
import { Logo } from 'shared/ui/icons/logo';
import { UserOctagon } from 'shared/ui/icons/user-octagon';

export const Header = () => {
  const items = [
    {
      key: '1',
      label: (
        <div>
          Выйти
        </div>
      ),
    },
  ];

  return (
    <header className={clsx(styles.wrapper)}>
      <NavLink
        to="/"
        className={clsx(styles.navLink)}
      >
        <Logo className={clsx(styles.logoIcon)} />
      </NavLink>
      <Dropdown
        menu={{
          items,
        }}
        placement="bottom"
        arrow
      >
        <div className={clsx(styles.userName)}>
          <UserOctagon />
          <Typography.Text>Вайс Д. Р.</Typography.Text>
        </div>
      </Dropdown>
    </header>
  );
};

import clsx from 'clsx';

import { IconProps } from 'types/interfaces/icons';

export const Calendar = ({
  fill = 'currentColor',
  className,
}: IconProps) => (
  <svg
    className={clsx(className)}
    width="25"
    height="24"
    viewBox="0 0 25 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.16675 5.75C7.75675 5.75 7.41675 5.41 7.41675 5V2C7.41675 1.59 7.75675 1.25 8.16675 1.25C8.57675 1.25 8.91675 1.59 8.91675 2V5C8.91675 5.41 8.57675 5.75 8.16675 5.75Z"
      fill={fill}
    />
    <path
      d="M16.1667 5.75C15.7567 5.75 15.4167 5.41 15.4167 5V2C15.4167 1.59 15.7567 1.25 16.1667 1.25C16.5767 1.25 16.9167 1.59 16.9167 2V5C16.9167 5.41 16.5767 5.75 16.1667 5.75Z"
      fill={fill}
    />
    <path
      d="M20.6667 9.83984H3.66675C3.25675 9.83984 2.91675 9.49984 2.91675 9.08984C2.91675 8.67984 3.25675 8.33984 3.66675 8.33984H20.6667C21.0767 8.33984 21.4167 8.67984 21.4167 9.08984C21.4167 9.49984 21.0767 9.83984 20.6667 9.83984Z"
      fill={fill}
    />
    <path
      d="M16.1667 22.75H8.16675C4.51675 22.75 2.41675 20.65 2.41675 17V8.5C2.41675 4.85 4.51675 2.75 8.16675 2.75H16.1667C19.8167 2.75 21.9167 4.85 21.9167 8.5V17C21.9167 20.65 19.8167 22.75 16.1667 22.75ZM8.16675 4.25C5.30675 4.25 3.91675 5.64 3.91675 8.5V17C3.91675 19.86 5.30675 21.25 8.16675 21.25H16.1667C19.0267 21.25 20.4167 19.86 20.4167 17V8.5C20.4167 5.64 19.0267 4.25 16.1667 4.25H8.16675Z"
      fill={fill}
    />
    <path
      d="M8.66675 14.5C8.53675 14.5 8.40674 14.47 8.28674 14.42C8.16674 14.37 8.05676 14.3 7.95676 14.21C7.86676 14.11 7.79673 14 7.74673 13.88C7.69673 13.76 7.66675 13.63 7.66675 13.5C7.66675 13.24 7.77676 12.98 7.95676 12.79C8.05676 12.7 8.16674 12.63 8.28674 12.58C8.46674 12.5 8.66676 12.48 8.86676 12.52C8.92676 12.53 8.98675 12.55 9.04675 12.58C9.10675 12.6 9.16675 12.63 9.22675 12.67C9.27675 12.71 9.32674 12.75 9.37674 12.79C9.41674 12.84 9.46673 12.89 9.49673 12.94C9.53673 13 9.56676 13.06 9.58676 13.12C9.61676 13.18 9.63676 13.24 9.64676 13.3C9.65676 13.37 9.66675 13.43 9.66675 13.5C9.66675 13.76 9.55674 14.02 9.37674 14.21C9.18674 14.39 8.92675 14.5 8.66675 14.5Z"
      fill={fill}
    />
    <path
      d="M12.1667 14.4999C11.9067 14.4999 11.6468 14.3899 11.4568 14.2099C11.4168 14.1599 11.3768 14.1099 11.3368 14.0599C11.2968 13.9999 11.2667 13.9399 11.2467 13.8799C11.2167 13.8199 11.1967 13.7599 11.1867 13.6999C11.1767 13.6299 11.1667 13.5699 11.1667 13.4999C11.1667 13.3699 11.1967 13.2399 11.2467 13.1199C11.2967 12.9999 11.3668 12.8899 11.4568 12.7899C11.7368 12.5099 12.1868 12.4199 12.5468 12.5799C12.6768 12.6299 12.7767 12.6999 12.8767 12.7899C13.0567 12.9799 13.1667 13.2399 13.1667 13.4999C13.1667 13.5699 13.1568 13.6299 13.1468 13.6999C13.1368 13.7599 13.1168 13.8199 13.0868 13.8799C13.0668 13.9399 13.0367 13.9999 12.9967 14.0599C12.9567 14.1099 12.9167 14.1599 12.8767 14.2099C12.7767 14.2999 12.6768 14.3699 12.5468 14.4199C12.4268 14.4699 12.2967 14.4999 12.1667 14.4999Z"
      fill={fill}
    />
    <path
      d="M8.66675 17.9999C8.53675 17.9999 8.40674 17.9699 8.28674 17.9199C8.16674 17.8699 8.05676 17.7999 7.95676 17.7099C7.86676 17.6099 7.79673 17.5099 7.74673 17.3799C7.69673 17.2599 7.66675 17.1299 7.66675 16.9999C7.66675 16.7399 7.77676 16.4799 7.95676 16.2899C8.05676 16.1999 8.16674 16.1299 8.28674 16.0799C8.65674 15.9199 9.09674 16.0099 9.37674 16.2899C9.41674 16.3399 9.46673 16.3899 9.49673 16.4399C9.53673 16.4999 9.56676 16.5599 9.58676 16.6199C9.61676 16.6799 9.63676 16.7399 9.64676 16.8099C9.65676 16.8699 9.66675 16.9399 9.66675 16.9999C9.66675 17.2599 9.55674 17.5199 9.37674 17.7099C9.18674 17.8899 8.92675 17.9999 8.66675 17.9999Z"
      fill={fill}
    />
  </svg>
);

import { RouteModel } from 'models/RouteModel';
import { RouteFeedItem } from '../route-feed-item';

interface Props {
  routes: RouteModel[],
}

export const RouteFeed = ({ routes }: Props) => {

  return (
    <>
      {routes?.map((route) => (
        <RouteFeedItem
          key={route.id}
          feed={route}
        />
      ))}
    </>
  );
};

export const loadStorage = <T>(name: string): T | undefined => {
  try {
    const serialized = localStorage.getItem(name);
    if (serialized === null) {
      return undefined;
    }
    return JSON.parse(serialized);
  } catch (err) {
    return undefined;
  }
};

export const saveStorage = <T>(name: string, data: T) => {
  try {
    const serialized = JSON.stringify(data);
    localStorage.setItem(name, serialized);
  } catch {
    // ignore write errors
  }
};

export const removeStorage = (name: string) => {
  try {
    localStorage.removeItem(name);
  } catch {
    // ignore write errors
  }
};

export const clearStorage = () => {
  localStorage.clear();
};

import clsx from 'clsx';

import { IconProps } from 'types/interfaces/icons';

export const Wallet = ({
  fill = 'currentColor',
  className,
}: IconProps) => (
  <svg
    className={clsx(className)}
    width="25"
    height="24"
    viewBox="0 0 25 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M18.24 22.75H6.76C4.27 22.75 2.25 20.73 2.25 18.24V11.51C2.25 9.02001 4.27 7 6.76 7H18.24C20.73 7 22.75 9.02001 22.75 11.51V12.95C22.75 13.36 22.41 13.7 22 13.7H19.98C19.63 13.7 19.31 13.83 19.08 14.07L19.07 14.08C18.79 14.35 18.66 14.72 18.69 15.1C18.75 15.76 19.38 16.29 20.1 16.29H22C22.41 16.29 22.75 16.63 22.75 17.04V18.23C22.75 20.73 20.73 22.75 18.24 22.75ZM6.76 8.5C5.1 8.5 3.75 9.85001 3.75 11.51V18.24C3.75 19.9 5.1 21.25 6.76 21.25H18.24C19.9 21.25 21.25 19.9 21.25 18.24V17.8H20.1C18.59 17.8 17.31 16.68 17.19 15.24C17.11 14.42 17.41 13.61 18.01 13.02C18.53 12.49 19.23 12.2 19.98 12.2H21.25V11.51C21.25 9.85001 19.9 8.5 18.24 8.5H6.76Z"
      fill={fill}
    />
    <path
      d="M3 13.16C2.59 13.16 2.25 12.82 2.25 12.41V7.84006C2.25 6.35006 3.19 5.00001 4.58 4.47001L12.52 1.47001C13.34 1.16001 14.25 1.27005 14.96 1.77005C15.68 2.27005 16.1 3.08005 16.1 3.95005V7.75003C16.1 8.16003 15.76 8.50003 15.35 8.50003C14.94 8.50003 14.6 8.16003 14.6 7.75003V3.95005C14.6 3.57005 14.42 3.22003 14.1 3.00003C13.78 2.78003 13.4 2.73003 13.04 2.87003L5.1 5.87003C4.29 6.18003 3.74 6.97006 3.74 7.84006V12.41C3.75 12.83 3.41 13.16 3 13.16Z"
      fill={fill}
    />
    <path
      d="M20.1 17.8002C18.59 17.8002 17.31 16.6802 17.19 15.2402C17.11 14.4102 17.41 13.6002 18.01 13.0102C18.52 12.4902 19.22 12.2002 19.97 12.2002H22.05C23.04 12.2302 23.8 13.0102 23.8 13.9702V16.0302C23.8 16.9902 23.04 17.7702 22.08 17.8002H20.1ZM22.03 13.7002H19.98C19.63 13.7002 19.31 13.8302 19.08 14.0702C18.79 14.3502 18.65 14.7302 18.69 15.1102C18.75 15.7702 19.38 16.3002 20.1 16.3002H22.06C22.19 16.3002 22.31 16.1802 22.31 16.0302V13.9702C22.31 13.8202 22.19 13.7102 22.03 13.7002Z"
      fill={fill}
    />
    <path
      d="M14.5 12.75H7.5C7.09 12.75 6.75 12.41 6.75 12C6.75 11.59 7.09 11.25 7.5 11.25H14.5C14.91 11.25 15.25 11.59 15.25 12C15.25 12.41 14.91 12.75 14.5 12.75Z"
      fill={fill}
    />
  </svg>
);

import clsx from 'clsx';

import { IconProps } from 'types/interfaces/icons';

export const ArrowRight = ({
  fill = 'currentColor',
  className,
}: IconProps) => (
  <svg
    className={clsx(className)}
    width="12"
    height="13"
    viewBox="0 0 12 13"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.91298 1.45549C6.52245 1.06497 5.88929 1.06497 5.49876 1.45549C5.10824 1.84602 5.10824 2.47918 5.49876 2.8697L8.20328 5.57422H1.38232C0.830039 5.57422 0.382324 6.02193 0.382324 6.57422C0.382324 7.1265 0.830039 7.57422 1.38232 7.57422H8.20357L5.49876 10.279C5.10824 10.6695 5.10824 11.3027 5.49876 11.6932C5.88929 12.0838 6.52245 12.0838 6.91298 11.6932L11.3247 7.28147C11.7153 6.89094 11.7153 6.25778 11.3247 5.86726L6.91298 1.45549Z"
      fill={fill}
    />
  </svg>
);

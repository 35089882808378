import clsx from 'clsx';

import { IconProps } from 'types/interfaces/icons';

export const Car = ({
  fill = 'currentColor',
  className,
}: IconProps) => (
  <svg
    className={clsx(className)}
    width="25"
    height="24"
    viewBox="0 0 25 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M20.8329 11.7501H4.83286C4.60286 11.7501 4.39286 11.6501 4.25286 11.4701C4.11286 11.3001 4.05286 11.0601 4.10286 10.8401L5.23286 5.44008C5.60286 3.69008 6.35286 2.08008 9.32286 2.08008H16.3529C19.3229 2.08008 20.0729 3.70008 20.4429 5.44008L21.5729 10.8501C21.6229 11.0701 21.5629 11.3001 21.4229 11.4801C21.2729 11.6501 21.0629 11.7501 20.8329 11.7501ZM5.75286 10.2501H19.9029L18.9629 5.75008C18.6829 4.44008 18.3529 3.58008 16.3429 3.58008H9.32286C7.31286 3.58008 6.98286 4.44008 6.70286 5.75008L5.75286 10.2501Z"
      fill={fill}
    />
    <path
      d="M20.7928 22.75H18.9128C17.2928 22.75 16.9828 21.82 16.7828 21.21L16.5828 20.61C16.3228 19.85 16.2928 19.75 15.3928 19.75H10.2728C9.37285 19.75 9.31285 19.92 9.08285 20.61L8.88285 21.21C8.67285 21.83 8.37285 22.75 6.75285 22.75H4.87285C4.08285 22.75 3.32285 22.42 2.79285 21.84C2.27285 21.27 2.02285 20.51 2.09285 19.75L2.65285 13.66C2.80285 12.01 3.24285 10.25 6.45285 10.25H19.2128C22.4228 10.25 22.8528 12.01 23.0128 13.66L23.5728 19.75C23.6428 20.51 23.3929 21.27 22.8729 21.84C22.3428 22.42 21.5828 22.75 20.7928 22.75ZM10.2728 18.25H15.3928C17.2128 18.25 17.6428 19.06 18.0028 20.12L18.2128 20.74C18.3828 21.25 18.3828 21.26 18.9228 21.26H20.8028C21.1728 21.26 21.5229 21.11 21.7729 20.84C22.0128 20.58 22.1229 20.25 22.0928 19.9L21.5329 13.81C21.4029 12.46 21.2428 11.76 19.2328 11.76H6.45285C4.43285 11.76 4.27285 12.46 4.15285 13.81L3.59285 19.9C3.56285 20.25 3.67285 20.58 3.91285 20.84C4.15285 21.11 4.51285 21.26 4.88285 21.26H6.76285C7.30285 21.26 7.30285 21.25 7.47285 20.75L7.67285 20.14C7.92285 19.34 8.29285 18.25 10.2728 18.25Z"
      fill={fill}
    />
    <path
      d="M4.83276 8.75H3.83276C3.42276 8.75 3.08276 8.41 3.08276 8C3.08276 7.59 3.42276 7.25 3.83276 7.25H4.83276C5.24276 7.25 5.58276 7.59 5.58276 8C5.58276 8.41 5.24276 8.75 4.83276 8.75Z"
      fill={fill}
    />
    <path
      d="M21.8328 8.75H20.8328C20.4228 8.75 20.0828 8.41 20.0828 8C20.0828 7.59 20.4228 7.25 20.8328 7.25H21.8328C22.2428 7.25 22.5828 7.59 22.5828 8C22.5828 8.41 22.2428 8.75 21.8328 8.75Z"
      fill={fill}
    />
    <path
      d="M12.8328 5.75C12.4228 5.75 12.0828 5.41 12.0828 5V3C12.0828 2.59 12.4228 2.25 12.8328 2.25C13.2428 2.25 13.5828 2.59 13.5828 3V5C13.5828 5.41 13.2428 5.75 12.8328 5.75Z"
      fill={fill}
    />
    <path
      d="M14.3328 5.75H11.3328C10.9228 5.75 10.5828 5.41 10.5828 5C10.5828 4.59 10.9228 4.25 11.3328 4.25H14.3328C14.7428 4.25 15.0828 4.59 15.0828 5C15.0828 5.41 14.7428 5.75 14.3328 5.75Z"
      fill={fill}
    />
    <path
      d="M9.83276 15.75H6.83276C6.42276 15.75 6.08276 15.41 6.08276 15C6.08276 14.59 6.42276 14.25 6.83276 14.25H9.83276C10.2428 14.25 10.5828 14.59 10.5828 15C10.5828 15.41 10.2428 15.75 9.83276 15.75Z"
      fill={fill}
    />
    <path
      d="M18.8328 15.75H15.8328C15.4228 15.75 15.0828 15.41 15.0828 15C15.0828 14.59 15.4228 14.25 15.8328 14.25H18.8328C19.2428 14.25 19.5828 14.59 19.5828 15C19.5828 15.41 19.2428 15.75 18.8328 15.75Z"
      fill={fill}
    />
  </svg>
);

import clsx from 'clsx';

import { IconProps } from 'types/interfaces/icons';

export const Telegram = ({
  fill = 'currentColor',
  className,
}: IconProps) => (
  <svg
    className={clsx(className)}
    xmlns="http://www.w3.org/2000/svg"
    width="24px"
    height="20px"
    viewBox="0 0 24 20"
    fill="none"
  >
    <path
      d="M 1.929688 9.503906 C 1.980469 9.480469 2.027344 9.457031 2.074219 9.433594 C 2.902344 9.042969 3.738281 8.675781 4.574219 8.304688 C 4.621094 8.304688 4.695312 8.253906 4.738281 8.234375 C 4.800781 8.207031 4.867188 8.179688 4.929688 8.148438 C 5.054688 8.09375 5.179688 8.042969 5.304688 7.988281 C 5.550781 7.878906 5.796875 7.769531 6.046875 7.660156 C 6.542969 7.441406 7.039062 7.226562 7.535156 7.007812 C 8.527344 6.570312 9.519531 6.136719 10.511719 5.699219 C 11.503906 5.265625 12.496094 4.828125 13.488281 4.394531 C 14.476562 3.960938 15.472656 3.523438 16.460938 3.089844 C 17.453125 2.652344 18.445312 2.21875 19.4375 1.78125 C 19.660156 1.683594 19.898438 1.539062 20.132812 1.496094 C 20.332031 1.460938 20.527344 1.390625 20.726562 1.351562 C 21.105469 1.277344 21.523438 1.25 21.886719 1.410156 C 22.011719 1.464844 22.128906 1.546875 22.222656 1.644531 C 22.683594 2.109375 22.621094 2.878906 22.523438 3.535156 C 21.84375 8.117188 21.164062 12.699219 20.484375 17.28125 C 20.394531 17.910156 20.265625 18.601562 19.78125 19 C 19.371094 19.335938 18.789062 19.371094 18.285156 19.230469 C 17.78125 19.085938 17.332031 18.785156 16.894531 18.488281 C 15.082031 17.257812 13.265625 16.023438 11.453125 14.792969 C 11.023438 14.5 10.542969 14.117188 10.546875 13.585938 C 10.550781 13.265625 10.734375 12.980469 10.925781 12.726562 C 12.503906 10.617188 14.777344 9.167969 16.46875 7.15625 C 16.707031 6.871094 16.894531 6.359375 16.566406 6.195312 C 16.371094 6.097656 16.148438 6.230469 15.96875 6.355469 C 13.734375 7.949219 11.5 9.546875 9.261719 11.136719 C 8.53125 11.660156 7.765625 12.191406 6.886719 12.320312 C 6.101562 12.4375 5.308594 12.210938 4.546875 11.980469 C 3.910156 11.789062 3.273438 11.589844 2.636719 11.386719 C 2.300781 11.28125 1.953125 11.164062 1.691406 10.917969 C 1.429688 10.675781 1.28125 10.265625 1.4375 9.941406 C 1.535156 9.738281 1.726562 9.609375 1.929688 9.503906 Z M 1.929688 9.503906"
      fill={fill}
    />
  </svg>
);

import { ReactNode, Suspense } from 'react';
import { Provider } from 'react-redux';
import { Spin } from 'antd';

import { store } from './store';

export const withStore = (component: () => ReactNode) => () =>
  (
    <Provider store={store}>
      <Suspense fallback={<Spin />}>{component()}</Suspense>
    </Provider>
  );
